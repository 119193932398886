import axios from "axios";
import Cookies from "js-cookie";
import { getAccessToken, getUserId, isLogged, logout } from "./loginUtilities";

export const getApi = async (path, args) => {
  let config = {
    params: { ...args },
  };
  if (isLogged()) {
    config = {
      ...config,
      headers: {
        Authorization: "Bearer " + getAccessToken(),
      },
    };
  }
  try {
    let response = await axios.get(
      process.env.REACT_APP_DRUID_ADDRESS + path,
      config
    );
    if (response.status === 200) {
      if (
        isLogged() &&
        response["headers"]["x-druid-auth-status"] === "expired"
      ) {
        const newToken = await refreshingToken();
        Cookies.set("idToken", newToken["id_token"], { expires: 1 });

        config = {
          ...config,
          headers: {
            Authorization: "Bearer " + newToken["id_token"],
          },
        };
        response = await axios.get(
          process.env.REACT_APP_DRUID_ADDRESS + path,
          config
        );
      }
      return response.data;
    }
  } catch (error) {
    // const status = error["response"]["status"];
    const druidTokenStatus =
      error["response"]["headers"]["x-druid-auth-status"];
    if (isLogged() && druidTokenStatus === "expired") {
      const newToken = await refreshingToken();
      Cookies.set("idToken", newToken["id_token"], { expires: 1 });
      config = {
        ...config,
        headers: {
          Authorization: "Bearer " + newToken["id_token"],
        },
      };
      const response = await axios.get(
        process.env.REACT_APP_DRUID_ADDRESS + path,
        config
      );
      return response.data;
    } else {
      return error;
    }
  }
};

export const login = async (email, password) => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_IDENTITY_ADDRESS + "accounts:signInWithPassword",
      {
        email: email,
        password: password,
        returnSecureToken: true,
      },
      {
        params: { key: process.env.REACT_APP_FIREBASE_APP_KEY },
        headers: {
          "content-type": "text/json",
        },
      }
    );

    if (response.status === 200) {
      Cookies.set("idToken", response.data["idToken"], { expires: 1 });
      Cookies.set("refreshToken", response.data["refreshToken"], {
        expires: 1,
      });
      localStorage.setItem("user", JSON.stringify(response.data));
      // await getUserData().then((e) => {
      //   localStorage.setItem("userData", JSON.stringify(e));
      // });
      // await refreshingToken();
      return response.status;
    }
  } catch (error) {
    // console.log(error.response.data);
    return error.response.data;
  }
};

export const refreshingToken = async () => {
  try {
    const response = await axios.post(
      process.env.REACT_APP_IDENTITY_ADDRESS + "token",
      {
        grant_type: "refresh_token",
        refresh_token: Cookies.get("refreshToken"),
      },
      {
        params: { key: process.env.REACT_APP_FIREBASE_APP_KEY },
        headers: {
          "content-type": "text/json",
        },
      }
    );
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    return error;
  }
};

export const lookup = async () => {
  const idToken = getAccessToken();
  if (!idToken) {
    logout();
  }

  try {
    const path = "token_valid";
    const response = await axios.get(
      process.env.REACT_APP_DRUID_ADDRESS + path,
      {
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      }
    );

    if (response.status === 200) {
      if (!response.data["token_valid"]) {
        const newToken = await refreshingToken();
        Cookies.set("idToken", newToken["id_token"], { expires: 1 });
        // window.location.reload();
      }
    }
  } catch (error) {
    logout();
    // if (error.response.data.error.message === "INVALID_ID_TOKEN") {
    //     const newToken = await refreshingToken();
    //     Cookies.set('idToken', newToken['id_token'], {expires: 1});
    //     window.location.reload();
    // } else {
    //     logout();
    // }
  }
};

export const getUserData = async () => {
  try {
    await lookup().then();
    const path = "users/get_user_info";
    const args = { user_id: getUserId() };
    const response = await axios.get(
      process.env.REACT_APP_DRUID_ADDRESS + path,
      {
        params: { ...args },
        headers: {
          Authorization: "Bearer " + getAccessToken(),
        },
      }
    );

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    logout();
    return error;
  }
};
