import React from "react";
import { formatCurrency, formatPercentage } from "../../../lib/utilities";
import SecuritySidebar from "../../Shared/SecuritySidebar/SecuritySidebar";

const Sidebar = ({ financial, related }) => {
  return (
    <div className="page-sidebar mt-20">
      {financial ? (
        <div>
          <div className="border-b border-black mb-7">
            <h3 className="text-lg font-extrabold">Market data</h3>
            {/* todo: questa data? */}
            <p className="text-xs text-gray-400 mb-2">
              (as of {financial["last_update"]})
            </p>
          </div>
          <dl className="grid grid-cols-2">
            <dt className="pb-1 mb-2 border-b border-gray-400">Share Price</dt>
            <dd className="pb-1 mb-2 border-b border-gray-400 text-right">
              <span className="text-xl font-extrabold">
                {financial["share_price"]}
              </span>
            </dd>
            <dt className="pb-1 mb-2 border-b border-gray-400">Market Cap</dt>
            <dd className="pb-1 mb-2 border-b border-gray-400 text-right">
              <span className="text-xl font-extrabold">
                {financial["market_cap"]}
              </span>
            </dd>
            <dt className="pb-1 mb-2 border-b border-gray-400">
              Average 3M Daily volume
            </dt>
            <dd className="pb-1 mb-2 border-b border-gray-400 text-right">
              <span className="text-xl font-extrabold">
                {financial["average_3m_daily_volume"]}
              </span>
            </dd>
            {financial["dividend"] ? (
              <>
                <dt className="pb-1 mb-2 border-b border-gray-400">Dividend</dt>
                <dd className="pb-1 mb-2 border-b border-gray-400 text-right">
                  <span className="text-xl font-extrabold">
                    {financial["dividend"]}
                  </span>
                </dd>
              </>
            ) : (
              ""
            )}
          </dl>
          {/*<dl className="grid grid-cols-2 mt-10">*/}
          {/*  <dt className="pb-1 mb-2 border-b border-gray-400">*/}
          {/*    Last Adjusted Price*/}
          {/*  </dt>*/}
          {/*  <dd className="pb-1 mb-2 border-b border-gray-400 text-right">*/}
          {/*    <span className="text-xl font-extrabold">*/}
          {/*      {formatCurrency(financial["last_adjusted_price"])}*/}
          {/*    </span>*/}
          {/*  </dd>*/}
          {/*  <dt className="pb-1 mb-2 border-b border-gray-400">Volatility</dt>*/}
          {/*  <dd className="pb-1 mb-2 border-b border-gray-400 text-right">*/}
          {/*    <span className="text-xl font-extrabold">*/}
          {/*      {financial["volatility"]}*/}
          {/*    </span>*/}
          {/*  </dd>*/}
          {/*  <dt className="pb-1 mb-2 border-b border-gray-400">*/}
          {/*    Variation 1 M*/}
          {/*  </dt>*/}
          {/*  <dd className="pb-1 mb-2 border-b border-gray-400 text-right">*/}
          {/*    <span className="text-xl font-extrabold">*/}
          {/*      {formatPercentage(financial["variation_1m"])}*/}
          {/*    </span>*/}
          {/*  </dd>*/}
          {/*  <dt className="pb-1 mb-2 border-b border-gray-400">Variation 3M</dt>*/}
          {/*  <dd className="pb-1 mb-2 border-b border-gray-400 text-right">*/}
          {/*    <span className="text-xl font-extrabold">*/}
          {/*      {formatPercentage(financial["variation_3m"])}*/}
          {/*    </span>*/}
          {/*  </dd>*/}
          {/*  <dt className="pb-1 mb-2 border-b border-gray-400">Variation 1Y</dt>*/}
          {/*  <dd className="pb-1 mb-2 border-b border-gray-400 text-right">*/}
          {/*    <span className="text-xl font-extrabold">*/}
          {/*      {formatPercentage(financial["variation_1y"])}*/}
          {/*    </span>*/}
          {/*  </dd>*/}
          {/*</dl>*/}
        </div>
      ) : (
        ""
      )}

      {related && related.length > 0 ? (
        <div className="mt-20">
          <SecuritySidebar related={related} linkable={false} />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Sidebar;
