import React from "react";
import {
  formatCurrency,
  formatPercentage,
  formatPlainPercentage,
} from "../../../lib/utilities";
import styles from "../../Security/security.module.scss";
import { Link } from "react-router-dom";

const Sidebar = ({ strategies }) => {
  // console.log(strategies);
  return (
    <div className="page-sidebar mt-20">
      <h3 className="text-lg font-extrabold">Other Druid Strategies</h3>
      {strategies.map((value, i) => (
        <div className="grid grid-cols-3 mb-5" key={i}>
          <div
            className={`col-span-2 pt-5 border-t border-gray-600 ${styles.security}`}
          >
            <Link to={`/strategy/${value["code"]}`}>
              <div className="flex flex-row">
                <span className={`text-lg font-bold mr-1 ${styles.symbol}`}>
                  {value["code"]}
                </span>
                <span className={`text-lg ${styles.name}`}>
                  {value["name"]}
                </span>
              </div>
              <span className={`text-base uppercase`}>{value["position"]}</span>
              <span className={`text-base`}>Leverage {value["leverage"]}</span>
              <span className={`text-xs text-gray-500 mt-1 ${styles.sector}`}>
                {value["description"]}
              </span>
              <span
                className={`w-48 bg-gray-300 mt-4 pl-3 py-1 pr-12 capitalize font-extrabold ${styles.signal}`}
              >
                {value["type"]}
              </span>
            </Link>
          </div>
          <div
            className={`flex items-center justify-end h-4/6 border-t border-gray-600`}
          >
            <span className={`text-3xl font-extrabold ${styles.confidence}`}>
              {formatPercentage(value["average_yearly_return"])}
            </span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
