import { useEffect, useState } from "react";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import StrategyBox from "../components/Strategies/StrategyBox/StrategyBox";
import { formatDateUS } from "../lib/utilities";

const Strategies = () => {
  const [lastUpdate, setLastUpdate] = useState(0);
  const [strategies, setStrategies] = useState([]);
  useEffect(() => {
    getApi("strategy_list").then((r) => {
      setLastUpdate(Date.parse(r[0]["last_update"]));
      setStrategies(r.filter((s) => s["public"]));
    });
  }, []);

  return (
    <>
      <Menu />
      <div className="page-container md:px-11 px-5">
        <Hgroup
          title="Automated trading strategies"
          subtitle={`for the week ending ${formatDateUS(lastUpdate)}`}
        />
      </div>
      <div className="grid grid-cols-12 md:px-11 px-5 pt-10 pb-10 lg:pb-24">
        <p className="col-span-11">
          To unlock the full potential of the Druid system, seamless integration
          with fully automated trading strategies is paramount. By embracing
          automation, users can capitalize on the system's efficiency and
          precision, allowing for swift execution of trades based on predefined
          rules. This not only enhances the speed of decision-making but also
          ensures that trading activities align consistently with the
          established strategy. The synergy between the Druid system and
          automated trading strategies empowers users to navigate the dynamic
          financial landscape more effectively, minimizing manual intervention
          and maximizing the system's capabilities for optimal performance
        </p>
      </div>
      <div className="grid grid-cols-12 gap-5 xl:gap-10 md:px-11 px-5">
        {strategies.map((strategy, index) => (
          <StrategyBox strategy={strategy} key={index} />
        ))}
      </div>
      <div className="grid grid-cols-12 md:px-11 px-5 pt-10 pb-6">
        <p className="col-span-10 text-xs">
          Above, you will see a comprehensive display of automated strategies
          currently active, executing live trades as well as running in
          simulated paper accounts for our diverse clientele, demonstrating
          real-time effectiveness and adaptability in various market conditions.
        </p>
      </div>
      <div className="grid md:grid-cols-2 mb-4 border-y border-gray-300">
        <div className="border-r md:px-11 px-5">
          <h3 className="uppercase font-bold my-6">Stock Strategies</h3>
          <dl className="mb-4 text-xs">
            <dt className="font-bold float-left">Strategy:</dt>
            <dd>
              Trading; the objective of the strategy is to generate absolute
              return.
            </dd>

            <dt className="font-bold float-left">
              Brief Short Trading Approach:
            </dt>
            <dd>Spot Short-Term Downtrends and Uptrend Reversals.</dd>

            <dt className="font-bold float-left">
              Brief Long Trading Approach:
            </dt>
            <dd>Spot Short-Term Uptrends and Downtrend Reversals.</dd>
          </dl>
          <dl className="mb-4 text-xs">
            <dt className="font-bold float-left">Tools Used:</dt>
            <dd>
              Algorithms and Neural Networks; Utilizing algorithms aids in the
              systematic and data-driven identification of patterns, trends, and
              potential trading opportunities. Neural networks, leveraging their
              ability to learn from data patterns, prove invaluable in
              predicting market trends and discerning intricate relationships
              within financial data.
            </dd>
            <dt className="font-bold float-left">Risk Management:</dt>
            <dd>
              Implement a stop-loss to cap potential losses and execution gaps.
            </dd>
            <dt className="font-bold float-left">Safety Measures:</dt>
            <dd>
              Manage risk by deciding beforehand the acceptable level of risk.
              Avoid overcommitting to a single stock; diversification is key to
              spreading risk across various stocks, mitigating the impact of
              significant losses in any one stock.
            </dd>
          </dl>
        </div>
        <div className="border-r md:px-11 px-5">
          <h3 className="uppercase font-bold my-6">Crypto Strategies</h3>
          <dl className="text-xs">
            <dt className="font-bold float-left">Strategy:</dt>
            <dd>
              Dynamic Buy & Hold; the objective of the strategy is to generate
              alpha over an extended timeframe.
            </dd>
            <dt className="font-bold float-left">
              Brief Buy & Hold Approach:{" "}
            </dt>
            <dd>Spot Short-Term Uptrends and Downtrend Reversals.</dd>
            <dt className="font-bold float-left">Tools Used:</dt>
            <dd>
              Algorithms and Neural Networks: The use of algorithms facilitates
              a systematic and data-driven approach to identifying patterns,
              trends, and potential opportunities. Neural networks, harnessing
              their capacity to learn from data patterns, play a crucial role in
              predicting trends in the cryptocurrency market.
            </dd>
            <dt className="font-bold float-left">Risk Management:</dt>
            <dd>Implement a stop-loss to cap potential losses of alpha.</dd>
            <dt className="font-bold float-left">Safety Measures:</dt>
            <dd>
              Manage risk by deciding beforehand the acceptable level of risk.
            </dd>
          </dl>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Strategies;
