import styles from "./security.module.scss";
import { Link } from "react-router-dom";
import { formatPercentage } from "../../lib/utilities";
import { getTrendIcon } from "../../lib/utilitiesComponent";
import React, { useState } from "react";

const SecuritySmallBox = ({ value, linkable }) => {
  const [link] = useState(linkable ? `/security/${value["symbol"]}` : "");
  return (
    <div className="grid grid-cols-3 mb-5">
      <div
        className={`col-span-2 pt-5 border-t border-gray-600 ${styles.security}`}
      >
        <Link to={link}>
          <span className={`text-lg font-bold ${styles.symbol}`}>
            {value["symbol"]}
          </span>
          <span className={`mt-2 mb-1 ${styles.name}`}>{value["name"]}</span>
          <span className={`text-xs text-gray-500 ${styles.sector}`}>
            {value["sector"]}
          </span>
          <span
            className={`w-40 bg-gray-300 mt-4 pl-3 py-1 pr-12 capitalize font-extrabold ${styles.signal}`}
          >
            {value["signal"]}
          </span>
        </Link>
      </div>
      <div className={`flex items-center h-4/6 border-t border-gray-600`}>
        <span className={`text-3xl font-extrabold ${styles.confidence}`}>
          {formatPercentage(value["confidence"])}
        </span>
        <span className={`${styles.confidence_trend}`}>
          {getTrendIcon(value["confidence_trend"])}
        </span>
      </div>
    </div>
  );
};

export default SecuritySmallBox;
