const Hgroup = ({ title, subtitle, h1class }) => {
  return (
    <div className="page-hgroup overflow-auto xl:mb-16 lg:mb-8 md:mb-16 mb-10 grid grid-cols-12">
      <div className="md:col-span-7 col-span-12">
        <h1
          className={`xl:mt-24 md:mt-10 mt-4 mb-1 text-xl xl:text-4xl lg:text-2xl md:text-4xl font-normal ${h1class}`}
        >
          {title}
        </h1>
        <p
          className="xl:text-xl font-medium leading-relaxed"
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </div>
    </div>
  );
};

export default Hgroup;
