import styles from "./security.module.scss";
import { Tooltip } from "flowbite-react";
import React from "react";

const TooltipBlock = ({ level, levelLabel, order }) => {
  return (
    <li className={`col-span-1 ${styles.tooltip}`}>
      {level === levelLabel.toLowerCase() ? (
        <Tooltip className="relative" content={levelLabel}>
          <div
            className={`bg-gray-300 h-4 ${styles.active} 
            ${order === 0 ? "rounded-l-lg" : ""}
            ${order === 2 ? "rounded-r-lg" : ""}
            `}
          ></div>
        </Tooltip>
      ) : (
        <div
          className={`bg-gray-300 h-4
            ${order === 0 ? "rounded-l-lg" : ""}
            ${order === 2 ? "rounded-r-lg" : ""}
        `}
        ></div>
      )}
      <span
        className={`block
        ${order === 1 ? "text-center" : ""}
        ${order === 2 ? "text-right" : ""}
      `}
      >
        {levelLabel}
      </span>
    </li>
  );
};
export default TooltipBlock;
