import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import HgroupEnhanced from "../components/Shared/Hgoup/HgroupEnhanced";
import LinearChart from "../components/Chart/LinearChart";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import Sidebar from "../components/Strategies/Sidebar/Sidebar";
import SecurityTable from "../components/Security/SecurityTable";

const Strategy = () => {
  const { strategyCode } = useParams();
  const [data, setData] = useState({});
  const [strategies, setStrategies] = useState([]);
  const [lines, setLines] = useState([]);

  useEffect(() => {
    const args = {
      strategy: strategyCode,
    };
    getApi("strategy_details", args).then((r) => {
      setStrategies(r["other_strategies"]);
      setData(r);
      const golive = Date.parse(r["info"]["golive_date"]);
      let lines = [];
      let real, backtest;
      let change = false;
      r["info"]["cumulated_returns"].forEach((e) => {
        backtest = e["value"];
        if (Date.parse(e["end_date"]) > golive) {
          real = e["value"];
        }
        lines = [
          ...lines,
          {
            date: e["end_date"],
            backtest: backtest,
            live: real,
            value: e["value"],
          },
        ];
      });
      setLines(lines);
    });
  }, [strategyCode]);

  return (
    <>
      <Menu />
      {data["info"] ? (
        <div className="page-container md:px-11 px-5">
          <HgroupEnhanced
            title={strategyCode}
            subtitle={`Leverage ${data["info"]["leverage"]}`}
            list={[
              data["info"]["description"],
              data["info"]["type"].toUpperCase(),
            ]}
            strategy={data["info"]["position"] + " " + data["info"]["name"]}
            date={data["info"]["last_update"]}
          />
        </div>
      ) : null}

      <div className="page-body md:grid grid-cols-3 gap-8 md:px-11 px-5">
        <div className="page-content md:col-span-2">
          <div>
            <h2 className="mt-10 text-3xl font-extrabold">Cumulated Return</h2>
            <LinearChart
              data={lines}
              xAxisKey="date"
              pointKey="backtest"
              colorLine="#F6C289"
              secondLine="live"
              colorSecondLine="#7CB6E1"
              legend={true}
            />
          </div>

          {data["info"] ? (
            <SecurityFooter
              footer={{
                average_yearly_return: data["info"]["average_yearly_return"],
                volatility: data["info"]["volatility"],
                sharpe_ratio: data["info"]["sharpe_ratio"],
              }}
            />
          ) : (
            ""
          )}
        </div>
        <Sidebar strategies={strategies} />
      </div>

      {data["info"] ? (
        <div className="mb-20">
          <h2 className="md:px-11 px-5 mt-10 text-3xl font-extrabold">
            Composition last week
          </h2>
          <h3 className="md:px-11 px-5 text-3xl font-extrabold">
            {data["info"]["last_update"]}
          </h3>
          {data["securities"] ? (
            <SecurityTable
              securityList={data["securities"]}
              linkable={data["info"]["access"]}
              type="securities"
            />
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
      <Footer />
    </>
  );
};

export default Strategy;
