import { useEffect, useState } from "react";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import SecurityTable from "../components/Security/SecurityTable";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import { formatDate, formatDateUS } from "../lib/utilities";
import { useParams } from "react-router-dom";

const Sectors = () => {
  const { strategyCode } = useParams();
  const [lastUpdate, setLastUpdate] = useState(0);
  const [sectors, setSectors] = useState([]);
  const [strategyName, setStrategyName] = useState("");
  const [strategyDescription, setStrategyDescription] = useState("");
  useEffect(() => {
    getApi("strategy_sectors", { strategy: strategyCode }).then((r) => {
      setStrategyName(r["strategy"]);
      setStrategyDescription(r["strategy_description"]);
      setSectors(r["sectors"].filter((sector) => sector["name"]));
      setLastUpdate(Date.parse(r["last_update"]));
    });
  }, []);

  return (
    <>
      <Menu />
      <div className="page-container md:px-11 px-5">
        <Hgroup
          title={strategyName}
          subtitle={`for the week ending ${formatDateUS(lastUpdate)}`}
        />
      </div>
      <div className="grid grid-cols-12 md:px-11 px-5 pt-10">
        <p className="col-span-11">{strategyDescription}</p>
      </div>
      <SecurityTable securityList={sectors} linkable={true} type="sectors" />

      <div className="grid grid-cols-12 border-b border-gray-300">
        <p className="col-span-12 md:col-span-9 py-6 md:py-8 px-5 md:px-11 text-xs">
          Here we provide a legend explaining the meaning of the data presented.
        </p>
      </div>
      <div className="grid md:grid-cols-2 md:mb-14 border-b border-gray-300">
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Confidence level:</b>
            This is the level of confidence attained by machine learning models
            and algorithms, depending on their 'degree of success' in
            understanding the market.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b border-gray-300">
          <p className="text-xs">
            <b className="block">Risk:</b>
            It is proportional to the security's volatility.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Reward:</b>
            the reward is greater as DruidAI demonstrates a high ability to
            generate long-term profits.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b border-gray-300">
          <p className="text-xs">
            <b className="block">Driver:</b>
            It's the market status identified by DruidAI for the security, upon
            which the prediction is based.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Active stocks:</b>
            Number of stocks with a signal within the sector as a percentage of
            all the stocks classified for that sector
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Sectors;
