import styles from "./hpgroup.module.scss";
import { formatDateUS } from "../../../lib/utilities";

const HgroupEnhanced = ({
  title,
  subtitle,
  list,
  recommendation,
  strategy,
  date,
}) => {
  return (
    <div className="page-hgroup overflow-auto mb-16 grid grid-cols-12">
      <div className="col-span-12">
        <div className="flex flex-col lg:flex-row mt-12 mb-3">
          <h1 className="mr-6 text-sm md:text-base">
            <b className="text-2xl sm:text-4xl mr-3">{title}</b> {subtitle}
          </h1>
          <ul className="flex align-bottom items-center md:items-end lg:mt-0 md:mt-2 sm:mt-0 text-sm md:text-base">
            {list.map((value, i) => (
              <li key={i} className={`${styles.headerList}`}>
                {value}
              </li>
            ))}
          </ul>
        </div>
        <div className="">
          <h1 className="text-2xl sm:text-4xl mb-0 sm:mb-1 font-normal">
            {recommendation ? (
              <>
                <span>Recommendation</span>:{" "}
                <b className="capitalize">{recommendation}</b>
              </>
            ) : (
              ""
            )}
            {strategy ? (
              <>
                <span>Strategy</span>: <b className="capitalize">{strategy}</b>
              </>
            ) : (
              ""
            )}
          </h1>
          <p>
            {/* todo: questa data? */}
            weekly update {formatDateUS(date)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default HgroupEnhanced;
