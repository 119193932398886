export function formatDate(
  data,
  formato = "it-IT",
  options = { year: "numeric", month: "2-digit", day: "2-digit" }
) {
  let dataformattata = new Date(data);
  return dataformattata.toLocaleDateString(formato, options);
}

export function formatDateUS(data) {
  return formatDate(data, "en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function formatCurrency(value, currency = "USD") {
  if (!value) return "";
  value = parseFloat(value);
  return value.toLocaleString("en-US", {
    style: "currency",
    currency: currency,
  });
}

export function formatPercentage(number) {
  const option = {
    style: "percent",
  };
  const formatter = new Intl.NumberFormat("en-US", option);
  return formatter.format(number);
}

export function formatPlainPercentage(number) {
  const option = {
    style: "percent",
    maximumFractionDigits: 2,
  };
  const formatter = new Intl.NumberFormat("en-US", option);
  return formatter.format(number / 100);
}
