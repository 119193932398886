import Cookies from "js-cookie";
import { getUserData } from "./druidCall";

export const getAccessToken = () => {
  return Cookies.get("idToken");
};

export const getUserId = () => {
  const u = JSON.parse(localStorage.getItem("user"));
  return u["localId"];
};

export const refreshUserData = async () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  await getUserData().then((e) => {
    userData = e;
    localStorage.setItem("userData", JSON.stringify(e));
  });
  return userData;
};

export const isLogged = () => {
  const idToken = getAccessToken();
  if (idToken === "undefined") {
    return false;
  }
  return !!idToken;
};

export const logout = () => {
  localStorage.removeItem("user");
  // localStorage.removeItem("userData");
  Cookies.remove("idToken");
  Cookies.remove("refreshToken");
  window.location.href = process.env.REACT_APP_BASENAME;
};
