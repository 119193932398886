import React from "react";
import styles from "./securityFooter.module.scss";
import { formatPercentage } from "../../../lib/utilities";

const SecurityFooter = ({ footer }) => {
  return (
    <div className="content-footer grid xl:grid-cols-3 gap-1 mb-24">
      <div className={`px-5 py-7 ${styles.footer_card}`}>
        <p className="mb-2">Average annual return</p>
        <p>
          <b className="text-3xl">
            {formatPercentage(footer["average_yearly_return"])}
          </b>
        </p>
      </div>
      <div className={`px-5 py-7 ${styles.footer_card}`}>
        <p className="mb-2">Average annual volatility</p>
        <p>
          <b className="text-3xl">{formatPercentage(footer["volatility"])}</b>
        </p>
      </div>
      <div className={`px-5 py-7 ${styles.footer_card}`}>
        <p className="mb-2">Sharpe ratio</p>
        <p>
          <b className="text-3xl">{footer["sharpe_ratio"]}</b>
        </p>
      </div>
    </div>
  );
};

export default SecurityFooter;
