import styles from "./strategybox.module.scss";
import { Link } from "react-router-dom";

const StrategyBox = ({ strategy }) => {
  return (
    <Link
      to={`/strategy/${strategy["code"]}`}
      className={`col-span-12 md:col-span-6 lg:col-span-4 flex flex-col px-6 py-10 ${styles.box}`}
    >
      <div className="uppercase text-lg font-bold mb-4">
        {strategy["position"]}
      </div>
      <h2 className="text-4xl mb-5">
        <span className="font-bold mr-2">{strategy["code"]}</span>
        <span>{strategy["name"]}</span>
      </h2>
      <div className="mb-4">Leverage {strategy["leverage"]}</div>
      <div className="mb-4">{strategy["description"]}</div>
      <div className="mt-auto uppercase font-bold">{strategy["type"]}</div>
    </Link>
  );
};
export default StrategyBox;
