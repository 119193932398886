import { useEffect, useState } from "react";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import SecurityTable from "../components/Security/SecurityTable";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import { formatDate, formatDateUS } from "../lib/utilities";

const Weekly = () => {
  const [lastUpdate, setLastUpdate] = useState(0);
  const [weekly, setWeekly] = useState([]);
  useEffect(() => {
    getApi("weekly_recommendations").then((r) => {
      setWeekly(r);
      setLastUpdate(Date.parse(r[0]["last_update"]));
    });
  }, []);

  return (
    <>
      <Menu />
      <div className="page-container md:px-11 px-5">
        <Hgroup
          title="Weekly recommendations"
          subtitle={`for the week ending ${formatDateUS(lastUpdate)}`}
        />
      </div>
      <SecurityTable securityList={weekly} linkable={true} type="securities" />

      <div className="grid grid-cols-12 border-b border-gray-300">
        <p className="col-span-12 md:col-span-9 py-6 md:py-8 px-5 md:px-11 text-xs">
          Here we provide a legend explaining the meaning of the data presented.
        </p>
      </div>
      <div className="grid md:grid-cols-2 md:mb-14 border-b border-gray-300">
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Confidence level:</b>
            This is the level of confidence attained by machine learning models
            and algorithms, depending on their 'degree of success' in
            understanding the market.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b border-gray-300">
          <p className="text-xs">
            <b className="block">1M - 3M:</b>
            These are the one-month and three-month percentage changes of the
            security.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Risk:</b>
            It is proportional to the security's volatility.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b border-gray-300">
          <p className="text-xs">
            <b className="block">Sector Relative Reward:</b>
            This value indicates DruidAI's ability to generate profit on the
            security compared to the average of all securities in its database
            from the same sector.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b md:border-r border-gray-300">
          <p className="text-xs">
            <b className="block">Reward:</b>
            the reward is greater as DruidAI demonstrates a high ability to
            generate long-term profits.
          </p>
        </div>
        <div className="pt-4 pr-36 pb-5 px-5 md:pl-11 border-b border-gray-300">
          <p className="text-xs">
            <b className="block">Driver:</b>
            It's the market status identified by DruidAI for the security, upon
            which the prediction is based.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Weekly;
