import { useEffect, useState } from "react";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import HPCard from "../components/HPCard/HPCard";
import Hgroup from "../components/Shared/Hgoup/Hgroup";
import { getApi } from "../lib/druidCall";
import { formatDateUS } from "../lib/utilities";
import { isLogged } from "../lib/loginUtilities";

const Homepage = () => {
  const [lastUpdateWeekly, setLastUpdateWeekly] = useState(0);
  const [lastUpdateStrategies, setLastUpdateStrategies] = useState(0);
  const [strategies, setStrategies] = useState([]);
  const [sectors, setSectors] = useState([]);

  useEffect(() => {
    getApi("strategy_list").then((r) => {
      if (r.length > 0) {
        setLastUpdateStrategies(Date.parse(r[0]["last_update"]));
      }
      setSectors(r.filter((s) => s["access"]));
    });
  }, []);

  useEffect(() => {
    getApi("weekly_recommendations").then((r) => {
      setLastUpdateWeekly(Date.parse(r[0]["last_update"]));
    });
  }, []);

  useEffect(() => {
    setStrategies([
      {
        title: (
          <>
            Weekly recommendations <br />
            <small>{`for the week ending ${formatDateUS(
              lastUpdateWeekly
            )}`}</small>
          </>
        ),
        description:
          "Each week, we showcase four investment ideas from our extensive database of over 6000 securities, aiming to give a detailed view of the analysis conducted for each individual security",
      },
      {
        title: (
          <>
            Automated trading strategies <br />
            <small>contact us to view active strategies</small>
            {/*<small>{`for the week ending ${formatDateUS(*/}
            {/*  lastUpdateStrategies*/}
            {/*)}`}</small>*/}
          </>
        ),
        description:
          "To unlock the full potential of the Druid system, seamless integration with fully automated trading strategies is paramount. By embracing automation, users can capitalize on the system's efficiency and precision, allowing for swift execution of trades based on predefined rules.",
      },
    ]);
  }, [lastUpdateWeekly, lastUpdateStrategies]);

  return (
    <>
      <Menu />
      <div className="homepage-container page-container md:px-11 px-5">
        {isLogged() ? (
          <Hgroup
            title="Welcome back!"
            subtitle="We're thrilled to see you again on our website. If there's anything you need, we're here to help. Enjoy your visit!"
            h1class="mb-7"
          />
        ) : (
          <Hgroup
            title="Try now."
            subtitle="Give this new tool a try and discover a world of efficiency and convenience in just a few clicks."
            h1class="mb-7"
          />
        )}
        {sectors && sectors.length > 0 ? (
          <div className="md:grid md:grid-cols-12 gap-8 mb-8 overflow-auto">
            {sectors.map((sector, index) => (
              <HPCard
                key={index}
                title={
                  <>
                    {sector["name"]} <br />
                    <small>{`for the week ending ${formatDateUS(
                      sector["last_update"]
                    )}`}</small>
                  </>
                }
                description={sector["description"]}
                link={`/${sector["sector"] ? "sectors" : "strategy"}/${
                  sector["code"]
                }`}
              />
            ))}
          </div>
        ) : (
          ""
        )}
        {!isLogged() && strategies && strategies.length > 0 ? (
          <div className="md:grid md:grid-cols-12 gap-8 mb-8 overflow-auto">
            <HPCard
              title={strategies[0]["title"]}
              description={strategies[0]["description"]}
              link="/weekly"
            />
            <HPCard
              title={strategies[1]["title"]}
              description={strategies[1]["description"]}
              link="mailto:hey@druid.vision"
            />
          </div>
        ) : (
          ""
        )}
      </div>
      <Footer />
    </>
  );
};

export default Homepage;
