import React from "react";
import styles from "./menu.module.scss";
import LogoDruid from "../../../images/logoDruid.svg";
import { Link } from "react-router-dom";
import { isLogged, logout } from "../../../lib/loginUtilities";

const Menu = () => {
  return (
    <header className={styles.header}>
      <div
        className={`md:mx-11 mx-5 border-y border-y-black ${styles.page_header_inner}`}
      >
        <Link to="/" className={styles.logo_wrapper}>
          <img src={LogoDruid} alt="Druid.Vision" />
        </Link>
        <nav className={styles.nav_menu}>
          <ul className="">
            {isLogged() ? (
              <li className="">
                <Link onClick={logout}>Logout</Link>
              </li>
            ) : (
              <li className="">
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Menu;
