import React from "react";
import styles from "../../Security/security.module.scss";
import { formatPercentage } from "../../../lib/utilities";
import { getTrendIcon } from "../../../lib/utilitiesComponent";
import { Tooltip } from "flowbite-react";
import TooltipBlock from "../../Security/TooltipBlock";

const SignalPanel = ({ security }) => {
  return (
    <div className="signal-panel grid xl:grid-cols-2 border-b border-gray-200">
      <div className="grid md:grid-cols-3 md:px-11 px-5 pt-10 pb-6 signal xl:border-r">
        <div className="md:col-span-1">
          <p className="font-bold mb-2">Signal confidence level</p>
          <div className="flex items-center">
            <span className={`text-3xl font-extrabold ${styles.confidence}`}>
              {formatPercentage(security["confidence"])}
            </span>
            <span className={`${styles.confidence_trend}`}>
              {getTrendIcon(security["confidence_trend"])}
            </span>
          </div>
        </div>
        <div className="md:col-span-2 grid md:grid-cols-2 xl:pr-10">
          <div className="xl:col-span-2 xl:flex justify-between pt-4 md:pt-0">
            <p className="font-bold">Driver</p>
            <p className="">{security["driver"]}</p>
          </div>
          <div className="xl:col-span-2 xl:flex justify-between pt-4 md:pt-0">
            <p className="font-bold">Annual average Druid return</p>
            <p className="text-green-600 font-bold text-2xl">
              {formatPercentage(security["annual_average_return"])}
            </p>
          </div>
        </div>
      </div>
      <div className="grid md:grid-cols-2 md:px-11 px-5 md:border-t">
        <div className="pt-4 md:pt-10 md:pr-10 lx:pl-10 pb-6 md:border-r">
          <p className="font-bold mb-6">Risk</p>
          <ul className="grid grid-cols-3 gap-1 w-full">
            {["Low", "Average", "High"].map((value, i) => (
              <TooltipBlock
                key={i}
                order={i}
                level={security["risk_level"]}
                levelLabel={value}
              />
            ))}
          </ul>
        </div>
        <div className="pt-4 md:pt-10 md:pl-10 pb-6">
          <p className="font-bold mb-6">Reward</p>
          <ul className="grid grid-cols-3 gap-1 w-full">
            {["Low", "Average", "High"].map((value, i) => (
              <TooltipBlock
                key={i}
                order={i}
                level={security["reward_level"]}
                levelLabel={value}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SignalPanel;
