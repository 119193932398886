import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApi } from "../lib/druidCall";
import Menu from "../components/Shared/Menu/Menu";
import Footer from "../components/Shared/Footer/Footer";
import SignalPanel from "../components/SecurityPage/SignalPanel/SignalPanel";
import Sidebar from "../components/SecurityPage/Sidebar/Sidebar";
import SecurityFooter from "../components/SecurityPage/SecurityFooter/SecurityFooter";
import LinearChart from "../components/Chart/LinearChart";
import HgroupEnhanced from "../components/Shared/Hgoup/HgroupEnhanced";

const Security = () => {
  const { securityCode, strategyCode } = useParams();
  const [data, setData] = useState({});
  const [historical, setHistorical] = useState([]);
  const [signal, setSignal] = useState({});

  useEffect(() => {
    const args = {
      security: securityCode,
      strategy: strategyCode,
    };
    getApi("security_details", args).then((r) => {
      setData(r);
      // console.log(r);
      setHistorical(
        [...r["historical"]].reverse().map((e) => {
          return { ...e };
        })
      );
    });
  }, [securityCode]);

  return (
    <>
      <Menu />
      {data["security"] ? (
        <div className="page-container md:px-11 px-5">
          <HgroupEnhanced
            title={data["security"]["symbol"]}
            subtitle={data["security"]["name"]}
            list={[
              data["strategy"],
              data["security"]["sector"],
              data["security"]["exchange"],
              data["security"]["currency"],
            ]}
            recommendation={data["security"]["signal"]}
            date={data["security"]["last_update"]}
          />
        </div>
      ) : null}

      {data["security"] ? <SignalPanel security={data["security"]} /> : null}

      <div className="page-body md:grid grid-cols-3 gap-8 md:px-11 px-5">
        <div className="page-content md:col-span-2">
          <div>
            <h2 className="mt-10 text-3xl font-extrabold">
              Historical Price Performance
            </h2>
            <LinearChart
              data={historical}
              xAxisKey="end"
              pointKey="adj_close"
              colorLine="#7CB6E1"
              tooltip={["signal"]}
            />
          </div>
          <div>
            <h2 className="mt-10 text-3xl font-extrabold">Cumulated Return</h2>
            <LinearChart
              data={historical}
              xAxisKey="end"
              pointKey="backtesting_real"
              colorLine="#F6C289"
              tooltip={["signal"]}
            />
          </div>
          {data["security"] ? (
            <p className="my-20">{data["security"]["reason"]}</p>
          ) : (
            ""
          )}
          {data["footer"] ? <SecurityFooter footer={data["footer"]} /> : ""}
        </div>
        <Sidebar financial={data["financial"]} related={data["related"]} />
      </div>
      <Footer />
    </>
  );
};

export default Security;
