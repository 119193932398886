import React, { useState } from "react";
import styles from "./security.module.scss";
import { formatPercentage } from "../../lib/utilities";
import { getTrendIcon } from "../../lib/utilitiesComponent";
import { Link, useParams } from "react-router-dom";

const SecurityRow = ({ security, linkable, type }) => {
  const { strategyCode } = useParams();
  const [link] = useState(
    linkable
      ? type === "securities"
        ? strategyCode
          ? `/security/${security["symbol"]}/${strategyCode}`
          : `/security/${security["symbol"]}`
        : `/sector/${strategyCode}/${security["name"]}`
      : ""
  );

  return (
    <>
      <div
        className={`col-span-2 pt-6 pb-3 px-5 md:pl-11 ${styles.security} ${styles.cell}`}
      >
        <Link to={link}>
          <span className={`text-lg font-bold ${styles.symbol}`}>
            {security["symbol"]}
          </span>
          <span className={`mt-2 mb-1 ${styles.name}`}>{security["name"]}</span>
          <span className={`text-xs text-gray-500 ${styles.sector}`}>
            {security["sector"]}
          </span>
          <span
            className={`w-40 bg-gray-300 mt-4 pl-3 py-1 pr-12 capitalize font-extrabold ${styles.signal}`}
          >
            {security["signal"]}
          </span>
        </Link>
      </div>
      <div className="lg:hidden block mt-6 px-5 md:pl-11 md:pr-0 self-center font-bold">
        Reason
      </div>
      <div
        className={`col-span-3 px-5 md:px-11 lg:pl-0 lg:pr-10 lg:pt-6 pb-3 0 text-sm text-gray-500 ${styles.cell}`}
      >
        <span className={styles.reason}>{security["reason"]}</span>
      </div>

      <div className="lg:hidden block mt-6 px-5 md:pl-11 md:pr-0 self-center font-bold">
        Confidence Level
      </div>

      <div
        className={`px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
      >
        <span className={`text-3xl font-extrabold ${styles.confidence}`}>
          {formatPercentage(security["confidence"])}
        </span>
        <span className={`${styles.confidence_trend}`}>
          {getTrendIcon(security["confidence_trend"])}
        </span>
      </div>

      <div
        className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
      >
        <span className="lg:hidden block mr-1">Risk</span>
        <span
          className={`font-bold lg:font-normal text-base lg:text-sm capitalize ${styles.risk_level}`}
        >
          {security["risk_level"]}
        </span>
        <span className={styles.risk_level_trend}>
          {getTrendIcon(security["risk_level_trend"])}
        </span>
      </div>

      <div
        className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
      >
        <span className="lg:hidden block mr-1">Reward</span>
        <span
          className={`font-bold lg:font-normal text-base lg:text-sm capitalize ${styles.reward_level}`}
        >
          {security["reward_level"]}
        </span>
        <span className={styles.risk_level_trend}>
          {getTrendIcon(security["reward_level_trend"])}
        </span>
      </div>

      {type === "securities" ? (
        <>
          <div
            className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
          >
            <span className="lg:hidden block mr-1">1M</span>
            <span
              className={`font-bold lg:font-normal text-base lg:text-sm capitalize lg:text-green-500 ${styles.m1}`}
            >
              {Number(security["m1"]).toFixed(2)}%
            </span>
          </div>

          <div
            className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
          >
            <span className="lg:hidden block mr-1">3M</span>
            <span
              className={`font-bold lg:font-normal text-base lg:text-sm capitalize lg:text-green-500 ${styles.m1}`}
            >
              {Number(security["m3"]).toFixed(2)}%
            </span>
          </div>

          <div
            className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center  ${styles.cell}`}
          >
            <span className="lg:hidden block mr-1">Sector Relative Reward</span>
            <span
              className={`font-bold lg:font-normal text-base lg:text-sm  capitalize ${styles.sector_relative_reward_level}`}
            >
              {security["sector_relative_reward_level"]}
            </span>
            <span
              className={`text-sm ${styles.sector_relative_reward_level_trend}`}
            >
              {getTrendIcon(security["sector_relative_reward_level_trend"])}
            </span>
          </div>
        </>
      ) : (
        ""
      )}

      <div
        className={`mt-3 lg:mt-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
      >
        <span className="lg:hidden block mr-1">Driver</span>
        <span
          className={`font-bold lg:font-normal text-base lg:text-sm ${styles.driver}`}
        >
          {security["driver"]}
        </span>
      </div>

      {type === "sectors" ? (
        <>
          <div className="lg:hidden block mt-6 px-5 md:pl-11 md:pr-0 self-center font-bold">
            Active stocks
          </div>
          <div
            className={`lg:border-b-0 border-b border-gray-600 pb-8 lg:pb-0 px-5 md:pl-11 md:pr-0 lg:pl-0 flex items-center ${styles.cell}`}
          >
            <span className={`flex items-center ${styles.confidence}`}>
              <span className="text-2xl font-extrabold">
                {formatPercentage(security["active_stocks"])}
              </span>
              <span className="text-base font-normal ml-2">
                (
                {security["active_stocks_count"] +
                  "/" +
                  security["total_stocks_count"]}
                )
              </span>
            </span>
          </div>
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default SecurityRow;
