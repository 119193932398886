import React, { useState } from "react";
import styles from "../../Security/security.module.scss";
import { Link } from "react-router-dom";
import { formatPercentage } from "../../../lib/utilities";
import { getTrendIcon } from "../../../lib/utilitiesComponent";
import SecuritySmallBox from "../../Security/SecuritySmallBox";

const SecuritySidebar = ({ related, linkable }) => {
  // const [link] = useState(linkable ? `/security/${value["symbol"]}` : "");
  return (
    <div>
      <h3 className="text-lg font-extrabold">Druid selected comparables</h3>
      {related.map((value, i) => (
        <div key={i}>
          <SecuritySmallBox value={value} linkable={false} />
        </div>
      ))}
    </div>
  );
};

export default SecuritySidebar;
