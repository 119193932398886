import styles from "./hpcard.module.scss";
import { Link } from "react-router-dom";

const HPCard = ({ title, description, link }) => {
  return (
    <Link
      to={`${link}`}
      className={`xl:col-span-5 lg:col-span-6 block col-span-12 xl-:mb-32 sm:mb-0 pb-8 mb-8 ${styles.card}`}
    >
      <div className="pl-6 pr-8 sm:pt-0 pt-1">
        <h2 className="lg:mt-16 md:mt-12 mt-8 mb-7 xl:text-4xl lg:text-2xl md:text-3xl text-2xl font-normal">
          {title}
        </h2>
        <p className="xl:text-xl lg:text-base font-medium leading-relaxed">
          {description}
        </p>
      </div>
    </Link>
  );
};

export default HPCard;
