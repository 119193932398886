import React from "react";
import styles from "./security.module.scss";
import SecurityRow from "./SecurityRow";

const SecurityTable = ({ securityList, linkable, type }) => {
  return (
    <div className={`overflow-x-auto`}>
      <div
        className={`${
          type === "securities" ? "lg:grid-cols-12" : "lg:grid-cols-10"
        } lg:mt-16 mt-4 lg:grid border-b border-gray-400 ${styles.tableWeekly}`}
      >
        <div className="hidden lg:block col-span-2 pl-11 self-center font-bold">
          Sectors
        </div>
        <div className="hidden lg:block col-span-3 self-center font-bold">
          Reason
        </div>
        <div className="hidden lg:block self-center font-bold">
          Confidence Level
        </div>
        <div className="hidden lg:block self-center font-bold">Risk</div>
        <div className="hidden lg:block self-center font-bold">Reward</div>
        {type === "securities" ? (
          <>
            <div className="hidden lg:block self-center font-bold">1M</div>
            <div className="hidden lg:block self-center font-bold">3M</div>
            <div className="hidden lg:block self-center font-bold">
              Sector Relative Reward
            </div>
          </>
        ) : (
          ""
        )}
        <div className="hidden lg:block self-center font-bold">Driver</div>
        {type === "sectors" ? (
          <>
            <div className="hidden lg:block self-center font-bold">
              Active stocks
            </div>
          </>
        ) : (
          ""
        )}
        {securityList.map((value, i) => (
          <SecurityRow
            key={i}
            security={value}
            linkable={linkable}
            type={type}
          />
        ))}
      </div>
    </div>
  );
};

export default SecurityTable;
