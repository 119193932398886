import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
} from "recharts";
import { useEffect, useState } from "react";
import { formatPercentage, formatPlainPercentage } from "../../lib/utilities";
import styles from "./chart.module.scss";
import { scaleSymlog, scaleLog } from "d3";

const CustomTooltip = ({ active, payload, label, data }) => {
  const [dataPayload, setDataPayload] = useState("");
  useEffect(() => {
    if (payload.length > 0) {
      setDataPayload(payload[0]["payload"][data[0]]);
      // for (let i = 0; i < data.length; i++) {
      //   setDataPayload(payload[0]["paylod"][data[i]]);
      // }
    }
  }, [payload]);

  if (active && payload && payload.length) {
    return (
      <>
        {dataPayload && dataPayload.length > 0 ? (
          <div
            className={`
          ${styles.tooltip}`}
          >
            <span
              className={`
            ${dataPayload === "strong sell" ? styles.strongSell : ""}
            ${dataPayload === "sell" ? styles.sell : ""}
            ${dataPayload === "neutral" ? styles.neutral : ""}
            ${dataPayload === "buy" ? styles.buy : ""}
            ${dataPayload === "strong buy" ? styles.strongBuy : ""}
            `}
            ></span>
            {dataPayload}
            {/*{dataPayload.map((value, i) => (*/}
            {/*  <p key={i}>{value}</p>*/}
            {/*))}*/}
          </div>
        ) : (
          ""
        )}
      </>
    );
  }

  return null;
};

const LinearChart = ({
  data,
  xAxisKey,
  pointKey,
  colorLine,
  secondLine,
  colorSecondLine,
  legend,
  tooltip,
}) => {
  const [filterActive, setFilterActive] = useState(0);
  const [dataChart, setDataChart] = useState({});
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(0);
  const scale = scaleSymlog([0, 100], [min, max]).constant(10);
  // console.log(data);

  useEffect(() => {
    setMin(Math.min(...data.map((item) => item[pointKey])));
    setMax(Math.max(...data.map((item) => item[pointKey])));
    setDataChart(data);
  }, [data]);

  const filterChart = (years) => {
    if (years) {
      const startingDate = new Date(
        new Date().setFullYear(new Date().getFullYear() - years)
      );
      const filterDate = data.filter(
        (obj) => Date.parse(obj[xAxisKey]) > startingDate.valueOf()
      );
      setFilterActive(years);
      setMin(Math.min(...filterDate.map((item) => item[pointKey])));
      setMax(Math.max(...filterDate.map((item) => item[pointKey])));
      setDataChart(filterDate);
    } else {
      setFilterActive(0);
      setMin(Math.min(...data.map((item) => item[pointKey])));
      setMax(Math.max(...data.map((item) => item[pointKey])));
      setDataChart(data);
    }
  };

  return (
    <ResponsiveContainer width="100%" height={600} className="relative">
      <div className="xl:absolute -top-5 z-10 mt-2 xl:mt-0 right-0 flex items-center">
        <p className="text-sm pr-2">Show history for: </p>
        <ul className="flex text-sm">
          <li
            className={`${
              filterActive === 1 ? styles.filterActive : ""
            } p-3 px-4 border rounded-l-lg border-r-0 cursor-pointer`}
            onClick={() => filterChart(1)}
          >
            1Y
          </li>
          <li
            className={`${
              filterActive === 3 ? styles.filterActive : ""
            } p-3 border cursor-pointer`}
            onClick={() => filterChart(3)}
          >
            3Y
          </li>
          <li
            className={`${
              filterActive === 0 ? styles.filterActive : ""
            } p-3 border rounded-r-lg border-l-0 cursor-pointer`}
            onClick={() => filterChart()}
          >
            Max
          </li>
        </ul>
      </div>
      <LineChart
        data={dataChart}
        margin={{
          top: 50,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          dataKey={xAxisKey}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          axisLine={false}
          tickLine={false}
          dataKey={pointKey}
          tickFormatter={(value, i) =>
            pointKey === "adj_close" ? value : formatPlainPercentage(value)
          }
          scale={pointKey === "adj_close" ? "auto" : scale}
          // domain={["auto", "auto"]}
          // allowDataOverflow={true}
          // domain={[min > 0 ? 0 : min, max]}
          domain={[min, max]}
        />
        {tooltip && tooltip.length > 0 ? (
          <Tooltip content={<CustomTooltip data={[...tooltip]} />} />
        ) : (
          ""
        )}
        {legend ? (
          <Legend align="right" iconType="plainline" height={80} />
        ) : (
          ""
        )}
        <Line
          type="bump"
          dataKey={pointKey}
          strokeWidth={3}
          stroke={colorLine}
          dot={false}
        />
        {secondLine ? (
          <Line
            type="bump"
            dataKey={secondLine}
            strokeWidth={3}
            stroke={colorSecondLine}
            dot={false}
          />
        ) : (
          ""
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LinearChart;
